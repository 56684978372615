import { Component, OnInit, ViewChild } from '@angular/core';
import { faSearch, faAngleDown, faSignInAlt, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationStart } from '@angular/router';
import { CommonService, DealsService, UserService } from '../service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  public href: string = "";
  // Filters Parameters Start
  isNavbarCollapsed: boolean = false;
  categorylist: any[];
  commodityList: any[];
  dispalyCommodityFilter: Boolean = false;
  searchedAddress: string;
  categoryMetaData = {
    name: "Category ",
    id: 0
  };
  commodityMetaData = {
    name: " Commodity ",
    noResult: "No Commodities"
  };
  cartChecked: boolean = false;
  shopChecked: boolean = false;
  lat: number;
  lng: number;
  options = {
    types: [],
    componentRestrictions: {
      country: 'IN'
    }
  }
  faSearch = faSearch;
  faAngleDown = faAngleDown;
  faFilter = faFilter;
  faTimes = faTimes;
  showLogin = true;
  nearByDropDown = false;

  // Filters Parameters End 

  logoObj = {
    onLoginPage: true
  }
  menuItems = [];
  isCollapsed = false;
  faSignInAlt = faSignInAlt;
  search = {
    hideOnSearch: false
  };
  crossMark = false;
  constructor(
    private router: Router,
    private commonService: CommonService,
    private dealsService: DealsService,
    private userService: UserService
  ) {
    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          // start loading pages
          if (event.url === "/deals") {
            this.search.hideOnSearch = true;
            this.loadDealsFilterAndDeals();
          } else {
            this.search.hideOnSearch = false;
          }
        }
      });
  }

  ngOnInit(): void {
    this.dealsService.selectedCategory.subscribe(category => {
      this.updateCategory(category);
    });
    this.userService.currentUser.subscribe(value => {
      this.showLogin = !this.userService.currentUserValue;
      // this.showLogin = !this.dealsService.currentUserValue;
    })
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      })
    }
    // this.showLogin = !this.userService.currentUserValue;
  }

  updateCategory(category: any) {
    if (category && category.label) {
      this.categoryMetaData.name = category.label
    }
  }

  logout() {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  /**
   * Load The Filter Option and the deals
   */
  loadDealsFilterAndDeals() {
    this.getCategoriesForFilters();
    this.searchedAddress = this.dealsService.dealsFilter.address ? this.dealsService.dealsFilter.address : '';
    this.showCrossMark();
    if (navigator.geolocation && !this.dealsService.dealsFilter.lat) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.dealsService.dealsFilter.lat = position.coords.latitude;
          this.dealsService.dealsFilter.lng = position.coords.longitude;
          this.dealsService.dealsFilter.lng = position.coords.longitude;
          this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
        }
      });
    }
  }
  
  // enableSearch() {
  //   if (!!this.dealsService.dealsFilter.lat) {
  //     this.enterPressedOnSearch()
  //   } else {

  //     this.dealsService.dealsFilter.lat = this.lat;
  //     this.dealsService.dealsFilter.lng = this.lng;
  //     this.dealsService.dealsFilter.address = "Near By"
  //     this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
  //     this.router.navigate(['/deals']);
  //   }
  // }



  crossMarkClick() {
    this.searchedAddress = '';
    this.crossMark = false;
  }

  /** 
  * Method: GET Categories List
  */

  getCategoriesForFilters() {
    if (this.commonService.listOfCategory.length === 0) {
      this.commonService.getCategories()
        .subscribe(categories => {
          this.categorylist = categories;
        });
    } else {
      this.categorylist = this.commonService.listOfCategory;
    }
  }

  /**
    * Method: GET Commodity List with respect to Category id 
    */
  getCommoditiesForFilters(id: number) {
    this.dispalyCommodityFilter = false;
    this.commonService.getCommodities(id)
      .subscribe(list => {
        this.dispalyCommodityFilter = true;
        this.commodityList = list.data;
      });
  }

  /**
    * Method: Selected Category
    * Params: category Object
    */

  selectCategory(category: any) {
    if (category) {
      this.dealsService.dealsFilter.category = [];
      this.dealsService.dealsFilter.commodity = [];
      this.categoryMetaData.name = category.label;
      this.categoryMetaData.id = category.id;
      this.getCommoditiesForFilters(category.id);
      this.dealsService.dealsFilter.category.push(category.id);
      this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
    }
  }

  shareCheckedList(item: any[]) {
    this.dealsService.dealsFilter.commodity = [];
    item.forEach(commodity => {
      this.dealsService.dealsFilter.commodity.push(commodity.id);
    });
    this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
  }

  shareIndividualCheckedList(item: any) {
    console.log(item);
  }

  cartShopCheckBoxtrigger() {
    this.dealsService.dealsFilter.types = null;
    if (this.cartChecked) {
      this.dealsService.dealsFilter.types = "CART";
    }
    if (this.shopChecked) {
      this.dealsService.dealsFilter.types = "SHOP";
    }
    this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
  }

  enableSearch() {
    if (!!this.dealsService.dealsFilter.lat) {
      this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
    } else {
        this.dealsService.dealsFilter.lat = this.lat;
        this.dealsService.dealsFilter.lng = this.lng;
        this.dealsService.dealsFilter.address = "Near By"
        this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
       // this.router.navigate(['/deals']);
      }
  }


  handleAddressChange(address: any) {
    // Do some stuff
    this.dealsService.resetDealsFilter();
    this.dealsService.dealsFilter.lat = address.geometry.location.lat();
    this.dealsService.dealsFilter.lng = address.geometry.location.lng();
    this.dealsService.dealsFilter.address = address.formatted_address;
    //this.dealsService.upDateStatusFilter("STARTED");
    //this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
    let element : HTMLElement = document.getElementById('mapSearch') as HTMLElement;
    element.click();
    //this.enableSearch();
    //this.router.navigate(['/deals']);
  }

  showCrossMark() {
    if (this.searchedAddress.length > 0) {
      this.crossMark = true;
    } else {
      this.crossMark = false;
    }
  }

  hideNearByDropDown() {
    if (this.searchedAddress.length === 0) {
      this.crossMark = false;
    }
    if (this.searchedAddress && (this.searchedAddress.length > 0)) {
      this.nearByDropDown = false;
      this.crossMark = true
    } else {
      this.nearByDropDown = true;
    }
  }

  searchNearBy($event) {
    $event.stopPropagation();
    this.dealsService.dealsFilter.lat = this.lat;
    this.dealsService.dealsFilter.lng = this.lng;
    this.dealsService.dealsFilter.address = "Near By"
    this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
    this.loadDealsFilterAndDeals()
    this.focusOutFunction()
   // this.router.navigate(['/deals']);
  }

  enterPressedOnSearch() {
    if (!!this.dealsService.dealsFilter.lat) {
      this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
      //this.router.navigate(['/deals']);
    }
  }

  focusOutFunction() {
    this.nearByDropDown = false;
  }

}
