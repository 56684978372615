
 
    
      <div class="d-flex" id="wrapper">

        <!-- Sidebar -->
        <div class="bg-light border-right" id="sidebar-wrapper">
          <!-- <div class="sidebar-heading">Start Bootstrap </div> -->
          <div class="list-group list-group-flush">
            <button class="list-group-item list-group-item-action bg-light" (click)="openProfile()">
              <fa-icon [icon]="faUser"></fa-icon>Profile
            </button>
            <button class="list-group-item list-group-item-action bg-light" (click)="openReviews()">
              <fa-icon [icon]="faStar"></fa-icon>Your reviews
            </button>
            <button class="list-group-item list-group-item-action bg-light" (click)="openLikes()">
              <fa-icon [icon]="faHeart"></fa-icon>Your likes
            </button>
            <!-- <a href="#" class="list-group-item list-group-item-action bg-light">Dashboard</a>
            <a href="#" class="list-group-item list-group-item-action bg-light">Shortcuts</a>
            <a href="#" class="list-group-item list-group-item-action bg-light">Overview</a>
            <a href="#" class="list-group-item list-group-item-action bg-light">Events</a>
            <a href="#" class="list-group-item list-group-item-action bg-light">Profile</a>
            <a href="#" class="list-group-item list-group-item-action bg-light">Status</a> -->
          </div>
        </div>
        <!-- /#sidebar-wrapper -->

         <!-- Page Content -->
 <div id="page-content-wrapper">

  <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
    <button class="btn btn-primary" id="menu-toggle" (click)="toggleMenu($event)">Toggle Menu</button>

    


  </nav>

  <div class="container-fluid">
    
    <div class="col col-md-9" *ngIf="showProfile">
      <div class="row">
        <div class="col">
          <label style="text-decoration: underline;">Personal information</label>
        </div>
      </div>
      <form [formGroup]="newRequirementForm">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label> First name</label>
              <input type="text" formControlName="summary" class="form-control inputClass" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label> Last name</label>
              <input type="text" formControlName="summary" class="form-control inputClass" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label> Email</label>
              <input type="text" formControlName="summary" class="form-control inputClass" />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label> Mobile number</label>
              <input type="text" formControlName="summary" class="form-control inputClass" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label style="text-decoration: underline;">Change password</label>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label> password</label>
              <input type="text" formControlName="summary" class="form-control inputClass" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col col-md-9" *ngIf="showReviews">
      <div [id] ="deal.id" class="row py-2 dealListDetails" *ngFor="let deal of dealsListForLocation">
      <div class="col-3">
        <img [src]="deal.photo_url" class="square100" />
        <ngb-carousel #carousel [interval]="interval" [pauseOnHover]="pauseOnHover" 
          
           class="remove-outline">
          <ng-template ngbSlide *ngFor="let img of deal.images; index as i">
            <div class="picsum-img-wrapper">
              <img [src]="img.url" class="square100border5">
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
      <div [id] ="deal.id" class="col-3">
        <div class="row">
          <span class="col font-weight-bolder font-size-20">{{deal.title}}</span>
          <span class="col float-right text-right"> {{deal.dealDate}} </span>
        </div>
        <div class="row">
          <span class="col font-size-12"> {{deal.categoryName}} </span>
          <span class="col float-right text-right">
            <strong class="orange-font"> {{ deal.currency.currencySymbol }}</strong> {{deal.pricePerUnit}}
          </span>
        </div>
        <div class="row">
          <span class="col">{{deal.startTime}} :: {{deal.endTime}} </span>
        </div>
        <div class="row">
          <span class="col">{{deal.place|firstname}} </span>
        </div>
        <div class="col">
          <div class="row alignStar">
            <div class="col-xs">
              <ng-template #t let-fill="fill">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                </span>
              </ng-template>
    
              <ngb-rating [(rate)]="deal.averageDealRating" [starTemplate]="t" [readonly]="readonly" [max]="5"></ngb-rating>
            </div>
            <div class="col alignCount">
              <pre><b>({{deal.dealRatingCount}})</b></pre>
            </div>
          </div>
          
        </div>
      </div>
    </div>
    </div>
    <div class="col-9" *ngIf="showLikes">
      <div class="row">
        <div class="col">
          <label style="text-decoration: underline;">likes</label>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /#page-content-wrapper -->
    
      
    
      </div>
      <!-- /#wrapper -->
      <!-- <div>
        <button class="btn col" ></button>
      </div>
      <div>
        <button class="btn col" (click)="openProfile()">
          <fa-icon [icon]="faUser"></fa-icon>Profile
        </button>
      </div>
      <div>
        <button class="btn col" ></button>
      </div>
      
      <div>
        <button class="btn col" (click)="openReviews()">
          <fa-icon [icon]="faStar"></fa-icon>Your reviews
        </button>
      </div>
      <div>
        <button class="btn col" ></button>
      </div>
      <div>
        <button class="btn col" (click)="openLikes()">
          <fa-icon [icon]="faHeart"></fa-icon>Your likes
        </button>
      </div> -->
    
 
 




 


