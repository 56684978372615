import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  public policy;

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loadPrivacyPolicy();
  }

  loadPrivacyPolicy() {
    this.commonService.getPrivatePolicy()
      .subscribe(result => {
        this.policy = result.BODY;
      });
  }

}
