<div class="privacy-policy">
  <div [innerHTML]="policy"></div>
</div>

<!-- <div class="privacy-policy">
  <div class="page-header">
    <h1>Privacy Policy</h1>
  </div>
  <h5>Personal Information</h5>
  <p> Rectrix Technologies Pvt Ltd (“ RTPL”) is the licensed owner of the brand mintocart.com and the
    website mintocart.com (”The Site. This Privacy Policy provides succinctly the manner your data
    is collected and used by RTPL on the Site. As a visitor to the Site/ Customer, you are advised to
    please read the Privacy Policy carefully. By accessing the services provided by the Site you agree
    to the collection and use of your data by RTPL in the manner provided in this Privacy Policy.
  </p>

  <h5> What information is, or maybe, collected from you? </h5>
  <p> As part of the registration process on the Site, RTPL may collect the following personally
    identifiable information about you: Name including first and last name, alternate email address,
    mobile phone number and contact details, Postal code, Demographic profile (like your age,
    gender, occupation, education, address etc.) and information about the pages on the site you
    visit/access, the links you click on the site, the number of times you access the page and any such
    browsing information.
  </p>

  <h5>How do we collect the Information?</h5>
  <p> RTPL will collect personally identifiable information about you only as part of a voluntary
    registration process, on-line survey or any combination thereof. The Site may contain links to
    other Web sites. RTPL is not responsible for the privacy practices of such Web sites which it
    does not own, manage or control. The Site and third-party vendors, including Google, use first-
    party cookies (such as the Google Analytics cookie) and third-party cookies (such as the
    DoubleClick cookie) together to inform, optimize, and serve ads based on someone's past visits to
    the Site.</p>

  <h5>How is the information used?</h5>
  <p> RTPL will use your personal information to provide personalized features to you on the Site and
    to provide for promotional offers to you through the Site and other channels. RTPL will also
    provide this information to its business associates and partners to get in touch with you when
    necessary to provide the services requested by you. RTPL will use this information to preserve
    transaction history as governed by existing law or policy. RTPL may also use contact information
    internally to direct its efforts for product improvement, to contact you as a survey respondent, to
    notify you if you win any contest; and to send you promotional materials from its contest
    sponsors or advertisers. RTPL will also use this information to serve various promotional and
    advertising materials to you via display advertisements through the Google Ad network on third
    party websites. You can opt-out of Google Analytics for Display Advertising and customize
    Google Display Network ads using the Ads Preferences Manager. Information about Customers
    on an aggregate (excluding any information that may identify you specifically) covering
    Customer transaction data and Customer demographic and location data may be provided to
    partners of RTPL for the purpose of creating additional features on the website, creating
    appropriate merchandising or creating new products and services and conducting marketing
    research and statistical analysis of customer behaviour and transactions.</p>

  <h5>With whom your information will be shared?</h5>
  <p> RTPL will not use your financial information for any purpose other than to complete a
    transaction
    with you. RTPL does not rent, sell or share your personal information and will not disclose any
    of
    your personally identifiable information to third parties. In cases where it has your permission to
    provide products or services, you've requested and such information is necessary to provide these
    products or services with the information may be shared with RTPL’s business associates and
    partners. RTPL may, however, share consumer information on an aggregate with its partners or
    third parties where it deems necessary. In addition, RTPL may use this information forpromotional
    offers, to help
    investigate, prevent or take action regarding unlawful and illegal
    activities, suspected fraud, potential threat to the safety or security of any person, violations of the
    Site’s terms of use or to defend against legal claims; special circumstances such as compliance
    with subpoenas, court orders, requests/order from legal authorities or law enforcement agencies
    requiring such disclosure.</p>

  <h5>What choices are available to you regarding the collection, use and distribution of your information?</h5>
  <p>To protect against the loss, misuse and alteration of the information under its control, RTPL
    has
    in place appropriate physical, electronic and managerial procedures. For example, RTPL servers
    are accessible only to authorized personnel and your information is shared with employees and
    authorised personnel on a need to know basis to complete the transaction and to provide the
    services requested by you. Although RTPL will endeavour to safeguard the confidentiality of
    your personally identifiable information, transmissions made by means of the Internet cannot be
    made absolutely secure. By using this site, you agree that RTPL will have no liability for
    disclosure of your information due to errors in transmission or unauthorized acts of third parties.
    How can you correct inaccuracies in the information? To correct or update any
    information you have provided, the Site allows you to do it online. In the event of loss of access
    details, you can send an email to: info@mintocart.com</p>

  <h5>Policy updates</h5>
  <p> RTPL reserves the right to change or update this policy at any time. Such changes shall be
    effective immediately upon posting to the Site.</p>

  <h5>Contact Information</h5>

  <address>
    Rectrix Technologies Pvt. Ltd. <br>
    VK towers NO. 3284,<br>
    KR Road, Banashankari 2nd Stage <br>
    Bangalore, Karnataka 560070 <br>
    Phone - 080-4091-3264
  </address>
</div> -->
