import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { SearchDealComponent } from './search-deal/search-deal.component';
import { DisplayDealsComponent } from './display-deals/display-deals.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AlertComponent } from './common-component/alert/alert.component';
import { MultiSelectorDropdownComponent } from './common-component/multi-selector-dropdown/multi-selector-dropdown.component';
import { GooglePlacesDirective } from './directive/google-places.directive';
import { LoaderComponent } from './common-component/loader/loader.component';
import { SearchPipe } from './pipes/search.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { FirstnamePipe } from './pipes/firstname.pipe';
import { SelectFirstPipe } from './pipes/select-first.pipe';
import { GoogleAnalyticsService } from './service/google-analytics.service';
import { PrivacyPolicyComponent } from './common-component/privacy-policy/privacy-policy.component';
import { RatingModalComponent } from './common-component/modals/rating-modal/rating-modal.component';
import { ProfileComponent } from './profile/profile.component';


@NgModule({
  declarations: [
    AppComponent,
    SearchDealComponent,
    DisplayDealsComponent,
    AppHeaderComponent,
    FooterComponent,
    LoginComponent,
    RegisterComponent,
    AlertComponent,
    MultiSelectorDropdownComponent,
    GooglePlacesDirective,
    LoaderComponent,
    SearchPipe,
    HighlightPipe,
    FirstnamePipe,
    SelectFirstPipe,
    PrivacyPolicyComponent,
    RatingModalComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCjlW_F5gnM8Gf24Zgumq4cJ44EC4O_h0c'
    }),
    AgmDirectionModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    library.add(fas);
  }
}
