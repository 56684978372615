import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faAngleDown, faSearch } from '@fortawesome/free-solid-svg-icons';
interface mutiSelectMetaData {
  name: String;
}

@Component({
  selector: 'multi-selector-dropdown',
  templateUrl: './multi-selector-dropdown.component.html',
  styleUrls: ['./multi-selector-dropdown.component.scss']
})

export class MultiSelectorDropdownComponent {
  @Input() list: any[];
  @Input() metaData: mutiSelectMetaData;

  @Output() shareCheckedList = new EventEmitter();
  @Output() shareIndividualCheckedList = new EventEmitter();

  faAngleDown = faAngleDown;
  faSearch = faSearch;
  showDropDown: Boolean = false;
  checkedList: any[];
  currentSelected: {};
  searchedTerm: "";

  constructor() {
    this.checkedList = [];
  }
  // Selected Value 
  getSelectedValue(status: Boolean, value: Object) {
    if (status) {
      this.checkedList.push(value);
    } else {
      var index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = value;

    //share checked list
    this.shareCheckedlist();
    //share individual selected item
    this.shareIndividualStatus();
    //arrange checked on top
    this.arrangeCheckedOnTop();
  }
  shareCheckedlist() {
    this.shareCheckedList.emit(this.checkedList);
  }
  shareIndividualStatus() {
    this.shareIndividualCheckedList.emit(this.currentSelected);
  }
  arrangeCheckedOnTop() {
    let checkedList = [];
    let uncheckedList = [];
    this.list.forEach(item => {
      if (item.checked) {
        checkedList.push(item);
      } else {
        uncheckedList.push(item);
      }
    });
    this.list = [];
    this.list = checkedList.concat(uncheckedList);
  }
}