import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class DateTimeService {
    getDisplayDate(dateObj) {
        const date = new Date(dateObj);
        const options = {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        };
        return date.toLocaleDateString("en-US", options);
    }

    getDisplayTime(TimeObj) {
        const time = new Date(TimeObj);
        const options = {
            hour12: true,
            hour: "2-digit",
            minute: "2-digit"
        };
        return time.toLocaleTimeString("en-US", options);
    }
}