import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch, faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DealsService, CommonService, UserService } from '../service';
import { MapsAPILoader } from '@agm/core';
declare var google: any;
@Component({
  selector: 'app-search-deal',
  templateUrl: './search-deal.component.html',
  styleUrls: ['./search-deal.component.scss']
})
export class SearchDealComponent implements OnInit {
  faSearch = faSearch;
  faTimes = faTimes;
  faMapMarker = faMapMarkerAlt;
  nearByDropDown = false;
  crossMark = false;
  lat: number;
  lng: number;
  listOfCategories: Array<any> = [];
  options = {
    types: [],
    strictBounds: true
  }
  currentLocation: string;

  public handleAddressChange(address: any) {
    // Do some stuff
    this.dealsService.dealsFilter.lat = address.geometry.location.lat();
    this.dealsService.dealsFilter.lng = address.geometry.location.lng();
    this.dealsService.dealsFilter.address = address.formatted_address;
  }



  constructor(private router: Router,
    private apiloader: MapsAPILoader,
    private commonService: CommonService,
    private userService: UserService,
    private elRef: ElementRef,
    private dealsService: DealsService) {
     
    }

  ngOnInit(): void {
    this.googleMapLoading();
    this.dealsService.resetDealsFilter();
    this.userService.CheckUserLogin();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
        }
      })
      if (this.commonService.listOfCategory.length === 0) {
        this.commonService.getCategories()
          .subscribe(categories => {
            this.listOfCategories = categories;
          });
      } else {
        this.listOfCategories = this.commonService.listOfCategory;
      }
    }
  }

  googleMapLoading(){
    const autocomplete = new google.maps.places.Autocomplete(
   );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(['geometry', 'formatted_address']);

    // When the user selects an address from the drop-down, populate the
    // Event listener to monitor place changes in the input
    // google.maps.event.addListener(autocomplete, 'place_changed', () => {
    //   //Emit the new address object for the updated place
    //   this.onSelect.emit(autocomplete.getPlace());
    // });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        var circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  enterPressedOnSearch() {
    if (!!this.dealsService.dealsFilter.lat) {
      this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
      this.router.navigate(['/deals']);
    }
  }


  hideNearByDropDown() {
    if (this.currentLocation.length === 0) {
      this.crossMark = false;
    }
    if (this.currentLocation && (this.currentLocation.length > 0)) {
      this.nearByDropDown = false;
      this.crossMark = true
    } else {
      this.nearByDropDown = true;
    }
  }

  crossMarkClick() {
    this.currentLocation = '';
    this.crossMark = false
  }




  searchNearBy($event) {
    $event.stopPropagation();
    this.dealsService.dealsFilter.lat = this.lat;
    this.dealsService.dealsFilter.lng = this.lng;
    this.dealsService.dealsFilter.address = "Near By"
    this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
    this.router.navigate(['/deals']);
  }

  focusOutFunction() {
    this.nearByDropDown = false;
  }

  goToDealsPage(item: any) {
    this.dealsService.dealsFilter.category = [];
    this.dealsService.changeCategory(item);
    this.dealsService.dealsFilter.category.push(item.id);
    this.router.navigate(['/deals']);
  }

  enableSearch() {
    if (!!this.dealsService.dealsFilter.lat) {
      this.enterPressedOnSearch()
    } else {

      this.dealsService.dealsFilter.lat = this.lat;
      this.dealsService.dealsFilter.lng = this.lng;
      this.dealsService.dealsFilter.address = "Near By"
      this.dealsService.updateDealsFilter(this.dealsService.dealsFilter);
      this.router.navigate(['/deals']);
    }
  }

  loadThisAddressToInput(address: any) {
    this.currentLocation = address;
  }

  readCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        if (position) {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.apiloader.load().then(() => {
            let position = { lat: this.lat, lng: this.lng };
            this.getLocationData(position, function (locationData: string) {
              this.loadThisAddressToInput(locationData);
              console.log(this)
            });
          });
        }
      })
    }
  }

  getLocationData(position, callback) {
    var geocoder = new google.maps.Geocoder();
    var location = position;

    if (geocoder) {
      geocoder.geocode({ 'location': location }, function (results) {
        if (results[0]) {
          callback(results[0].formatted_address);
        } else {
          console.log('Not found');
        }
      });
    }
  }
}