<div class="container-fluid display-deals">
  <div class="row dealsContainer">
    <div class="col-12 col-lg-4 dealsList" *ngIf="!selectedDeal">
      <div class="row statusFilters">
        <button class="btn col" (click)="statusFilter('ENDED')"
          [ngClass]="{active: filtersOnDisplay.status === 'ENDED'}">Yesterday's</button>
        <button class="btn col" (click)="statusFilter('STARTED')"
          [ngClass]="{active: filtersOnDisplay.status === 'STARTED'}">Active</button>
        <button class="btn col" (click)="statusFilter('CREATED')"
          [ngClass]="{active: filtersOnDisplay.status === 'CREATED'}">Upcoming</button>
      </div>
      <div *ngIf="page.loading"  class="data-loading spinner-border spinner-border-sm mr-1"></div>
      <div [id] ="deal.id" class="row py-2 dealListDetails" *ngFor="let deal of dealsListForLocation" (mouseover)="openWindow(deal.id)"
        (click)="showUpDetails(deal)">
        <div class="col-3">
          <img [src]="deal.photo_url" class="square100" *ngIf="deal.images && deal.images.length === 0" />
          <ngb-carousel #carousel [interval]="interval" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)"
            [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators"
            *ngIf="deal.images.length > 0" class="remove-outline">
            <ng-template ngbSlide *ngFor="let img of deal.images; index as i">
              <div class="picsum-img-wrapper">
                <img [src]="img.url" class="square100border5">
              </div>
            </ng-template>
          </ngb-carousel>
        </div>
        <div [id] ="deal.id" class="col-8">
          <div class="row">
            <span class="col font-weight-bolder font-size-20">{{deal.title}}</span>
            <span class="col float-right text-right"> {{deal.dealDate}} </span>
          </div>
          <div class="row">
            <span class="col font-size-12"> {{deal.categoryName}} </span>
            <span class="col float-right text-right">
              <strong class="orange-font"> {{ deal.currency.currencySymbol }}</strong> {{deal.pricePerUnit}}
            </span>
          </div>
          <div class="row">
            <span class="col">{{deal.startTime}} :: {{deal.endTime}} </span>
          </div>
          <div class="row">
            <span class="col">{{deal.place|firstname}} </span>
          </div>
          <div class="col">
            <div class="row alignStar">
              <div class="col-xs">
                <ng-template #t let-fill="fill">
                  <span class="star" [class.full]="fill === 100">
                    <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                  </span>
                </ng-template>
      
                <ngb-rating [(rate)]="deal.averageDealRating" class="" [starTemplate]="t" [readonly]="readonly" [max]="5"></ngb-rating>
              </div>
              <div class="col alignCount">
                <pre><b>({{deal.dealRatingCount}})</b></pre>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    
      <div class="emptyBox" *ngIf="dealsListForLocation.length === 0 && !page.loading">
        Deals not available in your location
      </div>
    </div>
    <div [id]="selectedDeal.id" class="col-12 col-lg-4 dealsDetails" *ngIf="!!selectedDeal">
      <div class="row">
        <div class="col-6 py-2">
          <button class="btn" (click)="backToDealList(selectedDeal.id)">
            <fa-icon [icon]="faLongArrowAltLeft"></fa-icon> Back
          </button>
        </div>
        <div class="col-6 py-2">
          <button class="btn float-right" (click)="showDirection(selectedDeal)">
            <fa-icon [icon]="faDirections"></fa-icon> Direction
          </button>
        </div>
      </div>
      <section [ngClass]="{'directionAdded': direction && !isXtraLarge}">
        <div [id] ="selectedDeal.id" class="row pb-2 dealDetails">
          <div class="col-12">
            <img [src]="selectedDeal.photo_url" class="dealDetailImage" *ngIf="selectedDeal.images.length === 0" />
            <ngb-carousel #carousel [interval]="interval" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)"
              [showNavigationArrows]="true" [showNavigationIndicators]="showNavigationIndicators"
              *ngIf="selectedDeal.images.length > 0" class="remove-outline">
              <ng-template ngbSlide *ngFor="let img of selectedDeal.images; index as i">
                <div class="picsum-img-wrapper">
                  <img [src]="img.url" class="dealDetailImage">
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
       
        </div>
        <div class="row">
          <div class="col-6">
            <strong> {{ selectedDeal.title }} </strong>
            <span class="font-size-12">{{ selectedDeal.categoryName }}</span>
          </div>
          <div class="col-2">
            <div class="likeButton"   *ngIf="like" (click)="triggerLike(selectedDeal,feedBack)">
              <fa-icon [icon]="faHeart"></fa-icon>
            </div>
            <div class="disLikeButton"  *ngIf="disLike" (click)="triggerDisLike(selectedDeal,feedBack)">
              <fa-icon [icon]="faHeart"></fa-icon>
            </div>
          </div>
          <div class="col-6 topAlignment">
            {{ selectedDeal.profile.firstName }} {{ selectedDeal.profile.lastName }}
          </div>
       
        </div>
        <div class="row">
          <div class="col-6">
            Date<span class="orange-font">: </span> {{ selectedDeal.dealDate }}
          </div>
          <div class="col-6">
            Price
            <span class="orange-font">: </span>
            <span class="orange-font">{{ selectedDeal.currency.currencySymbol }}</span>
            {{ selectedDeal.pricePerUnit }}
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            Starts<span class="orange-font">: </span>{{ selectedDeal.startTime  }}
          </div>
          <div class="col-6">
            Ends<span class="orange-font">: </span>{{ selectedDeal.endTime }}
          </div>
        </div>
        <div class="row">
          <div class="col">
            Place<span class="orange-font">: </span> {{selectedDeal.place}}
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-xs-2 alignRatings">
              <span>Ratings</span>
            </div>
            <div class="col-xs-4">
              <ng-template #t let-fill="fill">
                <span class="star" [class.full]="fill === 100">
                  <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
                </span>
              </ng-template>
              <ngb-rating [(rate)]="selectedDeal.averageDealRating" class="ratingDeal" [starTemplate]="t" [readonly]="readonly" [max]="5"></ngb-rating>
            </div>
            <div class="col-2 alignCountInDt">
              <pre><b>({{selectedDeal.dealRatingCount}})</b></pre>
            </div>
          </div>
        </div>
        <div class="row write-review">
          <div class="col-6">
            <button class="review btn btn-primary" (click)="open(content,selectedDeal)">
              Write a Review
            </button>
          </div>
        </div>
      </section>
    </div>
    <div class="col-12 col-lg-8 dealsMap-holder" *ngIf="isXtraLarge || selectedDeal">
      <agm-map #gm [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false" [zoomControl]="true"
        (mapReady)="mapReady($event)">

        <agm-marker *ngFor="let marker of markers; let i = index" (markerClick)="clickedMarker(marker.label, i)"
          [latitude]="marker.lat" [longitude]="marker.lng" [label]="marker.label" [markerDraggable]="marker.draggable"
          (dragEnd)="markerDragEnd(marker, $event)" (mouseOver)="onMouseOver(infoWindow, gm)"
          (mouseOut)="onMouseOut(infoWindow, $event)" [iconUrl]="marker.url">

          <agm-info-window #infoWindow [isOpen]="isInfoWindowOpen(marker.id)">
            <strong> {{ marker.title }} </strong>
            <strong> {{ marker.categoryName }} </strong><br>
            <strong>
              <span class="orange-font">{{ marker.currency ? marker.currency.currencySymbol : " " }} </span>
              {{ marker.pricePerUnit }}
            </strong><br>
            <strong>Ends In: {{ marker.endTime }} </strong><br>
          </agm-info-window>

        </agm-marker>
        <agm-direction [origin]="origin" [destination]="destination" *ngIf="direction"></agm-direction>
      </agm-map>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <form [formGroup]="reviewRatingForm" (ngSubmit)="reviewSubmit()">
      <div class="row py-2">
        <div class="col d-flex justify-content-center">
          <ng-template #t let-fill="fill">
            <span class="starInModal" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>
         

          <ngb-rating [(rate)]="feedBack.rating" [starTemplate]="t" [readonly]="modalReadonly" [max]="5"></ngb-rating>
        </div>
      </div>
    
      <div class="row py-2">
        <div class="col">
          <label for="commentDescription"> Review </label>
          <textarea type="text" formControlName="commentDescription" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && reviewRating.commentDescription.errors }"></textarea>
          <div *ngIf="submitted && reviewRating.commentDescription.errors" class="invalid-feedback">
            <div *ngIf="reviewRating.commentDescription.errors.required"> Please add a review </div>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div (click)="postButton(selectedDeal,feedBack)" class="col d-flex justify-content-start reviewSubmitButton">
          <button type="button" class="btn btn-outline-dark"> Post </button>
        </div>
        <div (click)="d('Cross click')" class="col d-flex justify-content-start reviewSubmitButton">
          <button type="button" class="btn btn-outline-dark"> Cancel </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
