<div (mouseleave)="showDropDown = false" class="multi-select">
  <button class="drop-toggle btn flat" (click)="showDropDown=!showDropDown">
    <span *ngIf="checkedList.length <= 0">
      {{ metaData.name }}
    </span>
    <!-- <span *ngIf="checkedList.length>0">
      <span *ngFor="let item of checkedList">
        {{item.label}}
        <span>x</span>
      </span>
    </span> -->
    <fa-icon class="float-right" [icon]="faAngleDown"></fa-icon>
  </button>
  <div class="drop-show" *ngIf="showDropDown">
    <div class="row searchCommodity">
      <fa-icon class="col-2 icon" [icon]="faSearch"></fa-icon>
      <input class="form-control col-10" placeholder="search" type="text" [(ngModel)]="searchedTerm" autofocus>
    </div>
    <label *ngFor="let item of list | search:searchedTerm | selectFirst: 'checked'">
      <input type="checkbox" [(ngModel)]="item.checked" (change)="getSelectedValue(item.checked, item)" />
      <span [innerHTML]="item.label | highlight: 'searchedTerm'"></span>
    </label>
    <!-- <label *ngIf="list.length <=0">
      {{ metaData.noResult }}
    </label> -->
  </div>
</div>
