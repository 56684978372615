import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { of as observableOf, throwError as observableThrowError, Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private httpClient: HttpClient,
    private loaderService: LoaderService) { }

  /**
   * for making get api calls
   *
   * @param requestParam interface
   */
  get(requestParam): Observable<any> {
    // this.loaderService.display(true);
    const httpOptions = {
      headers: requestParam.header ? requestParam.header : this.getHeader(),
      params: requestParam.param
    };
    return this.httpClient.get(requestParam.url, httpOptions).pipe(
      mergeMap((data) => this.responseHandler(data)),
      catchError(this.handleHTTPError)
    );
  }

  /**
   * for making post api calls
   *
   * @param requestParam interface
   *
   */
  post(requestParam): Observable<any> {
    // this.loaderService.display(true);
    const httpOptions = {
      headers: requestParam.header ? this.getHeader(requestParam.header) : this.getHeader(),
      params: requestParam.param
    };
    return this.httpClient.post(requestParam.url, requestParam.data, httpOptions).pipe(
      mergeMap((data) => this.responseHandler(data)),
      catchError(this.handleHTTPError)
    );
  }

  /**
   * for making patch api calls
   *
   * @param requestParam interface
   *
   */
  patch(requestParam): Observable<any> {
    // this.loaderService.display(true);
    const httpOptions = {
      headers: requestParam.header ? requestParam.header : this.getHeader(),
      params: requestParam.param
    };
    return this.httpClient.patch(requestParam.url, requestParam.data, httpOptions).pipe(
      mergeMap((data) => this.responseHandler(data)),
      catchError(this.handleHTTPError)
    );
  }

  /**
   * for making patch api calls
   *
   * @param requestParam interface
   *
   */
  put(requestParam): Observable<any> {
    // this.loaderService.display(true);
    const httpOptions = {
      headers: requestParam.header ? requestParam.header : this.getHeader(),
      params: requestParam.param
    };
    return this.httpClient.put(requestParam.url, requestParam.data, httpOptions).pipe(
      mergeMap((data) => this.responseHandler(data)),
      catchError(this.handleHTTPError)
    );
  }

  /**
   * for making delete api calls
   * @param requestParam interface
   */
  delete(requestParam): Observable<any> {
    // this.loaderService.display(true);
    const httpOptions = {
      headers: requestParam.header ? requestParam.header : this.getHeader(),
      params: requestParam.param,
      body: requestParam.data
    };
    return this.httpClient.delete(requestParam.url, httpOptions).pipe(
      mergeMap((data) => this.responseHandler(data)),
      catchError(this.handleHTTPError)
    );
  }

  /**
   * for preparing headers
   */
  private getHeader(headers?) {
    const default_headers = {
      'Accept': 'application/json'
    };
    if (headers) {
      return { ...default_headers, ...headers };
    } else {
      return { ...default_headers };
    }
  }

  /**
   * Handle errors in API calls
   */
  handleError = (error) => {
    /**
     * show toaster message or alerts on api erros
     */
  }

  /**
   * Handle HTTP errors
   */
  handleHTTPError = (error) => {
    // this.loaderService.display(false);
    if (error instanceof HttpErrorResponse) {
      const errorMessage = error.error.error_description;
      return throwError(errorMessage);
    } else {
      return observableThrowError(error);
    }
  }

  responseHandler = (data) => {
    // this.loaderService.display(false);
    let response: any;
    if (data.data) {
      response = data.data;
    } else if (data.result) {
      response = data.result;
    } else {
      response = data;
    }
    /**
     * Handle the response and error accroding to the api structure
     */
    if (data['status_code'] && data['status_code'] !== 200) {
      // this.handleError(data);
      return observableThrowError(data);
    }
    return observableOf(response);
  }
}
