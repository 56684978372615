import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { constants } from './constants';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { DateTimeService } from './date-time.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { feedBack } from '../models/feedBack';

@Injectable({
  providedIn: 'root'
})
export class DealsService {
 // private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  sharedId:String;
  dealsFilter = {
    lat: null,
    lng: null,
    category: [],
    commodity: [],
    status: "STARTED",
    types: null,
    start: 0,
    length: 10,
    startPrice: 0,
    endPrice: 0,
    startTime: null,
    endTime: null,
    distance: 3000,
    address: null
  };
  // private filterStaus = new BehaviorSubject<String>(status);
  // getFilterStatus = this.filterStaus.asObservable();

  // upDateStatusFilter(status: String) {
  //   this.filterStaus.next(status);
  // }
  private filterChanges = new BehaviorSubject<Object>({});
  getDealsFilters = this.filterChanges.asObservable();

  updateDealsFilter(newFilter: object) {
    this.filterChanges.next(newFilter);
  };

  private categoryChange = new BehaviorSubject<Object>({});
  selectedCategory = this.categoryChange.asObservable();

  changeCategory(category: object) {
    this.categoryChange.next(category)
  }

  dealsData;
  shopIcon = {
    url: './assets/images/Deals/SHOP_Map_Pin.svg',
    scaledSize: {
      width: 36,
      height: 50
    }
  };
  cartIcon = {
    url: './assets/images/Deals/Cart_Map_Pin.svg',
    scaledSize: {
      width: 46,
      height: 60
    }
  };

  constructor(
    private httpService: HttpService,
    private dateTimeService: DateTimeService
  ) { 
    // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  

  // updateUserData(User: object) {
  //   this.currentUserSubject.next(User);
  // };

  // public get currentUserValue() {
  //   return this.currentUserSubject.value;
  // }

    
  setSharedId = (dealId) =>{
    this.sharedId = dealId
  }

  getSharedId =()=>{
    return this.sharedId;
  }

  getSingleDeal = (filter: any,id: String) => {
      //   const reqest = {
      //     url: environment.baseUrl + constants.API_VERSION + 'public/deals/'+ id,
      // };
      // return this.httpService.get(reqest).pipe(map((response) => {
      //   // this.dealsData = response;
      //   return this.singleDealFormatted(response);
      // }));

      if (!!filter) {
        let data = {
          "catatories": filter.category,
          "comodities": filter.commodity,
          "distance": filter.distance,
          "lat": filter.lat,
          "lon": filter.lng,
          "start": filter.start,
          "status": filter.status,
          "length": filter.length,
          "startPrice": filter.startPrice,
          "endPrice": filter.endPrice,
          "startTime": filter.startTime,
          "endTime": filter.endTime
        }
  
        if (filter.types) {
          data["types"] = filter.types
        }
        const reqest = {
          url: environment.baseUrl + constants.API_VERSION + 'public/deals/'+ id,
          data: data
        };
        return this.httpService.get(reqest).pipe(map((response) => {
          this.dealsData = response;
          return this.singleDealFormatted(response);
        }));
      }
    
  }
 

  getDeals = (filter: any) => {
    if (!!filter) {
      let data = {
        "catatories": filter.category,
        "comodities": filter.commodity,
        "distance": filter.distance,
        "lat": filter.lat,
        "lon": filter.lng,
        "start": filter.start,
        "status": filter.status,
        "length": filter.length,
        "startPrice": filter.startPrice,
        "endPrice": filter.endPrice,
        "startTime": filter.startTime,
        "endTime": filter.endTime
      }

      if (filter.types) {
        data["types"] = filter.types
      }
      const reqest = {
        url: environment.baseUrl + constants.API_VERSION + 'public/deals',
        data: data
      };
      return this.httpService.post(reqest).pipe(map((response) => {
        this.dealsData = response;
        return this.formateDealsData(response);
      }));
    }
  };

  resetDealsFilter() {
    this.dealsFilter = {
      lat: null,
      lng: null,
      category: [],
      commodity: [],
      types: null,
      status: "STARTED",
      start: 0,
      length: 100,
      startPrice: 0,
      endPrice: 0,
      startTime: null,
      endTime: null,
      distance: 3000,
      address: null
    };
  };

  singleDealFormatted(deal) {
    let formateDeals =[];
   
      formateDeals.push(
        {
          id: deal.id,
          lat: deal.geoPoint ? deal.geoPoint.lat : "",
          lng: deal.geoPoint ? deal.geoPoint.lon : "",
          url: deal.locationType === "SHOP" ? this.shopIcon : this.cartIcon,
          draggable: false,
          title: deal.commodity.label,
          profile: !!deal.profile ? deal.profile : {},
          currency: !!deal.profile ? deal.profile.dialCode : {},
          categoryName: deal.commodity.label,
          pricePerUnit: deal.price + '/' + deal.quantity + ' ' + deal.unit.label,
          place: this.getFormattedDealAddress(deal.dealAddress),
          startTime: this.dateTimeService.getDisplayTime(deal.startTime),
          endTime: this.dateTimeService.getDisplayTime(deal.endTime),
          dealDate: this.dateTimeService.getDisplayDate(deal.startTime),
          photo_url: deal.commodity.iconUrl,
          images: deal.dealImages,
          geoPoint: deal.geoPoint,
          averageDealRating:deal.averageDealRating,
          dealRatingCount:deal.dealRatingCount
        }
      )
  
    return formateDeals;
  };

  formateDealsData(deals) {
    let formateDeals = [];
    deals.forEach(deal => {
      formateDeals.push(
        {
          id: deal.id,
          lat: deal.geoPoint ? deal.geoPoint.lat : "",
          lng: deal.geoPoint ? deal.geoPoint.lon : "",
          url: deal.locationType === "SHOP" ? this.shopIcon : this.cartIcon,
          draggable: false,
          title: deal.commodity.label,
          profile: !!deal.profile ? deal.profile : {},
          currency: !!deal.profile ? deal.profile.dialCode : {},
          categoryName: deal.category.label,
          pricePerUnit: deal.price + '/' + deal.quantity + ' ' + deal.unit.label,
          place: this.getFormattedDealAddress(deal.dealAddress),
          startTime: this.dateTimeService.getDisplayTime(deal.startTime),
          endTime: this.dateTimeService.getDisplayTime(deal.endTime),
          dealDate: this.dateTimeService.getDisplayDate(deal.startTime),
          photo_url: deal.commodity.iconUrl,
          images: deal.dealImages,
          geoPoint: deal.geoPoint,
          averageDealRating:deal.averageDealRating,
          dealRatingCount:deal.dealRatingCount
        }
      )
    });
    return formateDeals;
  };

  getDealsImage(dealImages: any) {
    dealImages.forEach(image => {
      return image.url
    });
  }

  creatViewDeal(id: number, feedback: any) {
    const reqest = {
      url: environment.baseUrl + constants.API_VERSION + 'public/deals/' + id + '/activity',
      data: {
        "activity": feedback,
        // "commentDescription": feedback,
        // "commentTitle": feedback,
        // "like": feedback,
        // "rating": feedback
      }
    };
    return this.httpService.post(reqest).pipe(map((response) => {
      return response;
    }));
  }

  // viewPost(id:number, activityType:any) {
  //   const sessionAuth = JSON.parse(localStorage.getItem("auth"));
  //   const reqest = {
  //       url: environment.baseUrl + constants.API_VERSION + 'deals/' + id + '/activity',
  //       header: {
  //         Authorization: sessionAuth.auth_token
  //       },
  //       data:{
  //         "activity": activityType,
  //       } 
  //   };
  //   return this.httpService.post(reqest).pipe(map((response) => {
  //       return response;
  //   }));
  // }  

  reviewRatingPost(id:number, feedBack: any,  activityType:any) {
    const sessionAuth = JSON.parse(localStorage.getItem("auth"));
    const reqest = {
        url: environment.baseUrl + constants.API_VERSION + 'deals/' + id + '/activity',
        header: {
          Authorization: sessionAuth.auth_token
        },
        data:{
          "activity": activityType,
          "commentDescription": feedBack.commentDescription,
          "like": feedBack.like,
          "rating": feedBack.rating
        } 
    };
    return this.httpService.post(reqest).pipe(map((response) => {
        return response;
    }));
  }  


 

  getFeedBack = (id: number) => {
    const sessionAuth = JSON.parse(localStorage.getItem("auth"));
   
        const reqest = {
          url: environment.baseUrl + constants.API_VERSION + 'deals/' + id + '/activity',
          header: {
            Authorization: sessionAuth.auth_token
          },
      };
      return this.httpService.get(reqest).pipe(map((response) => {
          return response;
      }));
    
  }



  getFormattedDealAddress(address: any) {
    let formattedAddress = "";
    if (address) {
      let splittedAdress = address.formattedAddress ? address.formattedAddress.split(",") : null;
      if (splittedAdress && splittedAdress.length >= 5) {
        formattedAddress = splittedAdress.slice(-5, -3).join(", ") ;
      } else if (splittedAdress && splittedAdress.length < 5) {
        formattedAddress = splittedAdress.slice(-4, -2).join(", ")
      }
    }

    return formattedAddress;
  }
}
