import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
    transform(item, searchText) {
        searchText = searchText === undefined ? '': searchText;
        const replaceText = new RegExp(searchText, 'gi');
        return item.replace(replaceText, `<span class='orange-font'>${searchText}</span>`);
    }
}