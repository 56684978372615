<div class="container">
  <div class="row">
    <div class="col-sm-6 offset-sm-3">
      <h2>Register</h2>
      <form [formGroup]="registerForm" >
        <div class="form-group">
          <div class="row">
            <div class="col">
              <label for="firstName">First Name</label>
              <input type="text" formControlName="firstName" class="form-control"/>
              <!-- <div *ngIf="submitted && registration.firstName.errors" class="invalid-feedback">
                <div *ngIf="registration.firstName.errors.required">First Name is required</div>
              </div> -->
            </div>
            <div class="col">
              <label for="lastName">Last Name</label>
              <input type="text" formControlName="lastName" class="form-control"/>
              <!-- <div *ngIf="submitted && registration.lastName.errors" class="invalid-feedback">
                <div *ngIf="registration.lastName.errors.required">Last Name is required</div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input type="text" formControlName="email" class="form-control"/>
          <!-- <div *ngIf="fieldsErrors.emailError" class="invalid-feedback">
            <div *ngIf="fieldsErrors.emailError">Email or Phone is required</div>
            <div *ngIf="registration.email.errors.email">Email Formate is Invalid</div>
          </div> -->
        </div>
        <div class="form-group">
          <label for="phone">Phone No</label>
          <input type="text" formControlName="phone" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registration.phone.errors }" />
          <div *ngIf="submitted && registration.phone.errors" class="invalid-feedback">
            <div *ngIf="registration.phone.errors.required">Phone Number is required</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" formControlName="password" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && registration.password.errors }" />
          <div *ngIf="submitted && registration.password.errors" class="invalid-feedback">
            <div *ngIf="registration.password.errors.required">Password is required</div>
            <div *ngIf="registration.password.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>



        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary" (click)="registrationSubmit(content)">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Register
          </button>
          <a routerLink="/login" class="btn btn-link">Cancel</a>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #content let-c="close" let-d="dismiss">
  <!-- <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <form [formGroup]="reviewRatingForm" (ngSubmit)="reviewSubmit()">
      <div class="row py-2">
        <div class="col d-flex justify-content-center">
          <ng-template #t let-fill="fill">
            <span class="starInModal" [class.full]="fill === 100">
              <span class="half" [style.width.%]="fill">&#9733;</span>&#9733;
            </span>
          </ng-template>
         

          <ngb-rating [(rate)]="feedBack.rating" [starTemplate]="t" [readonly]="modalReadonly" [max]="5"></ngb-rating>
        </div>
      </div>
    
      <div class="row py-2">
        <div class="col">
          <label for="commentDescription"> Review </label>
          <textarea type="text" formControlName="commentDescription" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && reviewRating.commentDescription.errors }"></textarea>
          <div *ngIf="submitted && reviewRating.commentDescription.errors" class="invalid-feedback">
            <div *ngIf="reviewRating.commentDescription.errors.required"> Please add a review </div>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div (click)="postButton(selectedDeal,feedBack)" class="col d-flex justify-content-start reviewSubmitButton">
          <button type="button" class="btn btn-outline-dark"> Post </button>
        </div>
        <div (click)="d('Cross click')" class="col d-flex justify-content-start reviewSubmitButton">
          <button type="button" class="btn btn-outline-dark"> Cancel </button>
        </div>
      </div>
    </form>
  </div> -->





          <div class="modal-body">
              <!-- <div class="row" style="margin-right: 0px;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div> -->
              <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="row">
                  <div class="col-lg-12 col-sm-12">
                      <div class="col-lg-11 col-sm-11" style="marging-left: 5%;" >
                          <div class="row">
                              <div class="col-lg-12 col-sm-12 text-center">
                                  <label>You are just one step away <i class="fa fa-smile-o color-def"></i></label>
                                  <p>Please check your mobile for 6-digit verification code</p>
                              </div>
                          </div>

                          <div class="row">
                              <div class="col-sm-12 col-lg-12">
                                  <form [formGroup]="otpVerificationForm" (ngSubmit)="otpSubmit()">
                                      <!-- <div class="form-group has-error"></div>
                                      <div class="row form-group has-error"></div>
                                      <div class="row form-group">
                                          <div class="col-lg-4"><label class="control-label" for="drive_roadTaxBundle_verification_keyvalue">Verification Code</label></div>
                                          <div class="col-lg-7">
                                            <input type="text" id="drive_roadTaxBundle_verification_keyvalue" name="drive_roadTaxBundle_verification[keyvalue]" class="form-control" />
                                          </div>
                                      </div>
                                      <div class="row top-buffer1 bottom-buffer3 text-center">
                                          <button type="submit" id="drive_roadTaxBundle_verification_submit" name="drive_roadTaxBundle_verification[submit]" class="btn-system btn btn-primary">Verify</button>
                                          <input type="hidden" id="drive_roadTaxBundle_verification__token" name="drive_roadTaxBundle_verification[_token]" class="form-control" value="t4TGM7WU3ZpQwVpn__qVrPNv6WWMpvNUnKCpoBOyB30" />
                                      </div> -->
                                    <input  formControlName="otp" class="form-control"
                                      [ngClass]="{ 'is-invalid': otpError }" />
                                      <div *ngIf="otpError" class="invalid-feedback">
                                        <div *ngIf="otpError">Otp is required</div>
                                      </div>
                                      
                                    
                                    <div class="form-group" style="text-align: center">
                                      <!-- <div (click)="postButton(selectedDeal,feedBack)" class="col d-flex justify-content-start reviewSubmitButton">
                                        <button type="button" class="btn btn-outline-dark"> Post </button>
                                      </div> -->
                                      <div>{{otpVariable}}</div>
                                      <button type="submit"  class="btn-system btn btn-primary">Verify</button>
                                    </div>
                                  </form>
                                  <p>If you do not receive the verification code in 1 minute <a href="#" id="resend">click to resend Verification Code.</a></p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </ng-template>


