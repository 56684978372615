<div class="container">
  <div class="row">
    <div class="col-sm-6 offset-sm-3">
      <div class="login-form">
        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
        <h2>Login</h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="userEmail">Email or phone</label>
            <input type="text" formControlName="userEmail" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && loginFormGetter.userEmail.errors }" />
            <div *ngIf="submitted && loginFormGetter.userEmail.errors" class="invalid-feedback">
              <div *ngIf="loginFormGetter.userEmail.errors.required">userEmail or phone is required</div>
              <!-- <div *ngIf="loginFormGetter.userEmail.errors.email"> Invalid Email format</div> -->
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && loginFormGetter.password.errors }" />
            <div *ngIf="submitted && loginFormGetter.password.errors" class="invalid-feedback">
              <div *ngIf="loginFormGetter.password.errors.required">Password is required</div>
              <div *ngIf="loginFormGetter.password.errors.minlength">Password Minimum length required </div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loginForm.invalid" class="btn btn-primary">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Login
            </button>
            <a routerLink="/register" class="btn btn-link">Register</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
