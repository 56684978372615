import { Directive, OnInit, ElementRef, Output, EventEmitter, Input } from '@angular/core';
declare var google: any;

@Directive({
  selector: '[google-place]'
})
export class GooglePlacesDirective implements OnInit {
  // @Input() public getGeoCode = {};
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  private element: HTMLInputElement;

  constructor(
    private elRef: ElementRef
  ) {
    this.element = elRef.nativeElement;
  }

  googleMapLoading(){
    const autocomplete = new google.maps.places.Autocomplete(
      this.element, {
      types: []
    });
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields(['geometry', 'formatted_address']);

    // When the user selects an address from the drop-down, populate the
    // Event listener to monitor place changes in the input
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      //Emit the new address object for the updated place
      this.onSelect.emit(autocomplete.getPlace());
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        var circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }

  

  ngOnInit() {
    this.googleMapLoading();
  }
}