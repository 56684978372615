import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { constants } from './constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { JsonPipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    private url: String = environment.baseUrl + constants.API_VERSION;

    constructor(private httpService: HttpService) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    
    updateUserData(User: object) {
        this.currentUserSubject.next(User);
    };

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    login(emailOrPhone, password) {
        

        if(emailOrPhone.match(/^[0-9]+$/)){
            const reqest = {
                url: this.url + 'public/login?loginuser=91' + emailOrPhone + '&password=' + password +'&profileType='+constants.profileType,
            };
            return this.httpService.post(reqest).pipe(map((response) => {
                if (response && response.auth_token) {
                    localStorage.setItem("auth", JSON.stringify(response));
                    return response;
                }
            }));
        }else{
            const reqest = {
                url: this.url + 'public/login?loginuser=' + emailOrPhone + '&password=' + password +'&profileType='+constants.profileType,
            };
            return this.httpService.post(reqest).pipe(map((response) => {
                if (response && response.auth_token) {
                    localStorage.setItem("auth", JSON.stringify(response));
                    return response;
                }
            }));
        }
     
   
    }
    loadUserData(auth) {
        const jsonData = {
            url: environment.baseUrl + "/me",
            header: {
                Authorization: auth.auth_token
            }
        };
        return this.httpService.post(jsonData).pipe(map((response) => {
            if (response) {
                this.loadUserData(response);
                localStorage.setItem("currentUser", JSON.stringify(response));
                return response;
            }
        }));
    }

    CheckUserLogin() {
        const sessionAuth = JSON.parse(localStorage.getItem("auth"));
        if (!!sessionAuth) {
            this.loadUserData(sessionAuth).subscribe(data => console.log(data));
            return true
        } else {
            return false;
        }
    }

    logout() {
        localStorage.removeItem('auth');
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        
    }

    register(user: User) {

        user.profileType = constants.profileType;
        user.dialCodeId = 101;
        const reqest = {
            url: this.url + 'public/registration',
            data: user
        };
        return this.httpService.post(reqest).pipe(map((response) => {
            return response;
        }));
    }

    otpVerification(otp,phoneNo) {
        const reqest = {
            url: this.url + 'public/verify/otp?loginuser=91'+phoneNo+'&otp='+otp+'&profileType='+constants.profileType,
        
        };
        return this.httpService.post(reqest).pipe(map((response) => {
            if (response && response.auth_token) {
                localStorage.setItem("auth", JSON.stringify(response));
                return response;
            }
        }));
    }
}
