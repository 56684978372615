import { Injectable } from "@angular/core";
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { constants } from './constants';

@Injectable({
    providedIn: "root"
})
export class CommonService {
    public listOfCategory: Array<any> = [];
    constructor(
        private httpService: HttpService
    ) { }


    getCategories = () => {
        const reqest = {
            url: environment.baseUrl + constants.API_VERSION + 'public/categories?start=0&length=100'
        };
        return this.httpService.get(reqest).pipe(map((response) => {
            this.listOfCategory = response.data;
            return response.data;
        }));
    }

    getCommodities = (id: number) => {
        const reqest = {
            url: environment.baseUrl + constants.API_VERSION + 'public/categories/' + id + '/commodities?start=0&length=200'
        };
        return this.httpService.get(reqest).pipe(map((response) => {
            return response;
        }));
    }

    getPrivatePolicy = () => {
        const reqest = {
            url: environment.baseUrl + constants.API_VERSION + 'public/policy'
        };
        return this.httpService.get(reqest).pipe(map((response) => {
            return response;
        }));
    }

    rad(x) {
        return x * Math.PI / 180;
    };

    getDistance(p1, p2) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = this.rad(p2.lat() - p1.lat());
        var dLong = this.rad(p2.lng() - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(p1.lat())) * Math.cos(this.rad(p2.lat())) *
            Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
    };
}