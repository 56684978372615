<div class="container-fluid search-deal">
  <div class="row">
    <div class="col">
      <div class="card card-signin">
        <div class="card-body" (click)="focusOutFunction()">
          <h5 class="card-title text-center"> Many Deals<span class="orange-font">,</span> One App </h5>
          <div class="col-lg-6 col-md-6 col-12 mx-auto" (click)="$event.stopPropagation()">
            <input google-place type="text" class="form-control" placeholder="Search for location"
              (onSelect)="handleAddressChange($event)" [(ngModel)]="currentLocation"
              (keyup.enter)="enterPressedOnSearch()" (focus)="nearByDropDown = true"
              (keyup)="hideNearByDropDown()" autofocus />
            <fa-icon [icon]="faTimes" class="orange-font cross-icon" *ngIf="crossMark" (click)="crossMarkClick()">
            </fa-icon>
            <fa-icon [icon]="faSearch" class="orange-font search-icon" (click)="enableSearch()"></fa-icon>
            <div (click)="searchNearBy($event)" class="nearByDropDownText" *ngIf="nearByDropDown">
              Near By
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4 class="col-12 second-header"> What deal do you need <span class="orange-font">?</span> </h4>
  <div class="row">
    <div class="col-lg-2 col-md-4 col-sm-6 col-6 text-center mb-3" *ngFor="let item of listOfCategories">
      <button class="btn select-commodity" (click)="goToDealsPage(item)">
        <img [src]="item.iconUrl" class="square100">
        {{ item.label }}
      </button>
    </div>
  </div>
</div>
