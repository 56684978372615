import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { DealsService, LoaderService } from '../service';
import { Subscription } from 'rxjs';
import { faHeart, faStar, faUser } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  newRequirementForm: FormGroup;
  showProfile=true;
  showReviews=false;
  showLikes=false;
  faUser = faUser;
  faStar = faStar;
  faHeart = faHeart;
  readonly = true;
  dealsListForLocation = [];
  filtersOnDisplay = {
    status: 'STARTED'
  };

  // google maps zoom level

  lat: number;
  lng: number;

  // courosel Params Starts
  pauseOnHover = true;
  interval = 3000;
 

  // courosel Params Ends
  private subscription: Subscription;

  constructor(
    private formBuilder:FormBuilder,
    private dealService: DealsService,
  ) { }

  ngOnInit(): void {
    this.newRequirementForm = this.formBuilder.group({
      id: ["0", []],
      summary: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });
    // this.subscription = this.dealService.getDealsFilters.subscribe(dealsFilter => {
    //   this.filtersOnDisplay.status = this.dealService.dealsFilter.status;
    //   if (Object.keys(dealsFilter).length !== 0) {
    //     this.getDealsForLatLng(dealsFilter)
    //   }
    // });
  }

    /**
    * Method: GET Deals For deals Filters
    */
   getDealsForLatLng() {
    // this.backToDealList();
    let filter ={
      lat:16.262409899999998,
      lng:74.4880165,
      distance:3000,
      category:[],
      commodity:[],
      start:0,
      startPrice:0,
      endPrice:0,
      endTime:null,
      startTime:null,
      length:10,
      status:"STARTED"

    }
    this.lat = filter.lat;
    this.lng = filter.lng;
    this.dealService.getDeals(filter)
      .subscribe(
        deals => {
          if (deals.length) {
            this.dealsListForLocation = deals;
            // this.markers = deals;
          }
        });
  }

  openProfile() {
    this.showLikes = false;
    this.showProfile = true;
    this.showReviews = false;
  }

  toggleMenu(e){
    e.preventDefault();
   // $("#wrapper").toggleClass("toggled");
  }

  openReviews() {
    this.getDealsForLatLng()
    this.showLikes = false;
    this.showProfile = false;
    this.showReviews = true;
  }

  openLikes() {
    this.showLikes = true;
    this.showProfile = false;
    this.showReviews = false;
  }

}
