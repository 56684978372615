import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';

import { UserService, AlertService } from '../service';

@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  otpVerificationForm:FormGroup;
  loading = false;
  submitted = false;
  otpError = false
  otpVariable = '';
  phoneNo ='';
 

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private modalService: NgbModal,
  ) {
    // redirect to home if already logged in
    if (this.userService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.email]],
      phone: ['', [Validators.required,Validators.minLength(10)]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
    this.otpVerificationForm = this.formBuilder.group({
      otp:['',[Validators.required]]
    })

  }

  otpSubmit(){
    let otpField =this.otpVerificationForm.controls['otp'].value; //access any of your form fields like this
    if(otpField===""){
      this.otpError = true;
      return false
    }else{
      this.modalService.dismissAll();
      this.otpError = false;

      this.loading = true;
      this.userService.otpVerification(otpField,this.phoneNo)
        // .pipe(first())
        .subscribe(
          data => {
            this.loading = false;
          
            if(data !== null){
            this.userService.updateUserData(data);
            this.router.navigate(['']);
            }
          },
          error => {
            this.alertService.error(error.status_message);
            this.loading = false;
          });
    }
  }

  // open(content) {
  //   this.modalService.open(content);
  // }
  // convenience getter for easy access to form fields
  get registration() { 
    return this.registerForm.controls; 
  }



  registrationSubmit(content) {
    this.submitted = true;
    this.otpVariable = '';
    // this.fieldsErros.emailError = false;
    // this.fieldsErros.phoneError = false;
    // this.customValidationFunction()
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
  

    this.loading = true;
    this.userService.register(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
         this.loading = false;
          this.otpVariable = data.otp
          this.phoneNo = data.phone
          this.modalService.open(content);
          // this.alertService.success('Registration successful', true);
          // this.router.navigate(['/login']);
        },
        error => {
          this.alertService.error(error.status_message);
          this.loading = false;
        });
  }
}
