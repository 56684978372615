import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchDealComponent } from './search-deal/search-deal.component';
import { DisplayDealsComponent } from './display-deals/display-deals.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from './profile/profile.component';
import { PrivacyPolicyComponent } from './common-component/privacy-policy/privacy-policy.component';


const routes: Routes = [
  {
    path: '',
    component: SearchDealComponent,
    pathMatch: 'full'
  }, {
    path: 'deals',
    component: DisplayDealsComponent,
    pathMatch: 'full'
  }, {
    path: 'privacyPolicy',
    component: PrivacyPolicyComponent,
    pathMatch: 'full'
  }, {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  }, {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full'
  }, {
    path: 'profile',
    component: ProfileComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
